<template>
  <div>
    <div class="capacity_div">
      <span class="header-info">{{ capacityTime }}日产能</span>
      <el-table class="table_text" :data="tableData" border>
        <el-table-column prop="name" label="产线名称" align="center"></el-table-column>
        <el-table-column label="外观产能" align="center">
          <template slot-scope="scope">
            <el-input :min="0" type="number" v-model="scope.row.appearance"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="电性能产能" align="center">
          <template slot-scope="scope">
            <el-input :min="0" type="number" v-model="scope.row.performance"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="金钥匙产能" align="center">
          <template slot-scope="scope">
            <el-input :min="0" type="number" v-model="scope.row.goldenKey"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="包装产能" align="center">
          <template slot-scope="scope">
            <el-input :min="0" type="number" v-model="scope.row.pack"></el-input>
          </template>
        </el-table-column>
      </el-table>
      <el-button type="primary" class="capacity_button" :loading="loading" @click="onSubmit">全部提交</el-button>
    </div>
  </div>
</template>

<script>

export default {
  name: "Capacity",
  data() {
    return {
      tableData: [],
      capacityTime: '',
      capacityNum: 5,
      loading:false
    }
  },
  methods: {
    async onSubmit() {
      this.loading=true
      let {data: res} = await this.$axios.post('/busCapacity/saveCapacity',
          this.tableData,
          );
      if (res.code === 0) {
        this.$message.success('提交成功');
        let {data: ress} = await this.$axios.post('/busCapacity/selectCapacity');
        if (ress.code === 0) {
          this.capacityTime = ress.data[1].recordDate
          this.tableData = ress.data;
        }
      }else{
        this.$message.error('提交失败');
      }
      this.loading=false
    }
  },
  async created() {
    let {data: res} = await this.$axios.post('/busCapacity/selectCapacity');
    if (res.code === 0) {
      this.capacityTime = res.data[1].recordDate
      this.tableData = res.data;
    }else{
      this.$message.error('产能管理未知错误');
    }
  }
}
</script>

<style scoped>

.capacity_button {
  width: 200px;
  margin-top: 20px;
  margin-left: 37%;
}

.header-info {
  margin-left: 37%;
  font-weight: bold;
  font-size: 25px;
}

.table_text {
  margin-top: 15px;
}

.capacity_div{
  width: 60%;
  border: 1px solid #EBEEF5;
  border-radius: 5px;
  padding: 20px 20% 20px 20%;
}
</style>